import { z } from 'zod';

const SettingsSchema = z.object({
  apiUrl: z.string(),
  authUrl: z.string(),
  clientId: z.string(),
  accessTokenExpiration: z.number(),
  identityProviders: z.array(
    z.object({
      id: z.string(),
      identityProviderName: z.string(),
    }),
  ),
  featureFlags: z.object({
    chat: z.boolean().optional().default(false),
  }),
  __dev__: z
    .object({
      service_account_key: z.string().optional(),
    })
    .optional(),
});

export enum IdentityProvider {
  GITHUB = 'GITHUB',
}

export type SettingsData = z.infer<typeof SettingsSchema>;

export class Settings {
  private _settingsData: SettingsData;

  private constructor(settingsData: SettingsData) {
    this._settingsData = settingsData;
  }

  get settingsData() {
    return this._settingsData;
  }

  static async load(url: string = '/settings.json'): Promise<Settings> {
    const response = await fetch(url);
    const body = await response.json();
    return Settings.fromJSON(body);
  }

  static fromJSON(json: any): Settings {
    const settingsData = SettingsSchema.parse(json);
    return new Settings(settingsData);
  }
}
