import { z } from 'zod';

const PermissionsSchema = z.object({
  administration: z.string().optional(),
  checks: z.string().optional(),
  contents: z.string().optional(),
  issues: z.string().optional(),
  metadata: z.string().optional(),
  pull_requests: z.string().optional(),
});

const AccountSchema = z.object({
  login: z.string(),
  id: z.number(),
  node_id: z.string(),
  avatar_url: z.string(),
  html_url: z.string(),
  gravatar_id: z.string(),
  type: z.string(),
  site_admin: z.boolean(),
  url: z.string(),
  events_url: z.string(),
  following_url: z.string(),
  followers_url: z.string(),
  gists_url: z.string(),
  organizations_url: z.string(),
  received_events_url: z.string(),
  repos_url: z.string(),
  starred_url: z.string(),
  subscriptions_url: z.string(),
});

export const InstallationSchema = z.object({
  id: z.number(),
  app_id: z.number(),
  app_slug: z.string(),
  target_id: z.number(),
  account: AccountSchema,
  access_tokens_url: z.string(),
  repositories_url: z.string(),
  html_url: z.string(),
  target_type: z.string(),
  repository_selection: z.string(),
  events: z.array(z.string()),
  permissions: PermissionsSchema,
  created_at: z.string(),
  updated_at: z.string(),
  has_multiple_single_files: z.boolean(),
});

export type InstallationData = z.infer<typeof InstallationSchema>;

export class Installation {
  private _installationData: InstallationData;

  constructor(installationData: InstallationData) {
    this._installationData = installationData;
  }

  get installationData() {
    return this._installationData;
  }
}
