'use client';

import { useStore } from '@/lib/zustand';
import useSessionStore from '@/stores/session-store';
import nullifyLogo from '@public/brand/nullify-full-logo-white.png';
import nullifyStars from '@public/brand/stars.png';
import Image from 'next/image';
import Link from 'next/link';
import { DashboardTopNavigation } from './dashboard-top-navigation';
import { InstallationSwitcher } from './installation-switcher';

export function SiteHeader() {
  const sessionStore = useStore(useSessionStore, (state) => state);

  return (
    <header className="fixed top-0 w-full bg-gradient-to-r from-nullifyGradientLeft to-nullifyGradientRight text-white">
      <Image
        src={nullifyStars}
        alt="stars"
        className="absolute z-[-1] w-full -translate-y-1/3 transform"
      />
      <div className="max-w-screen container z-10 flex h-14 items-center space-x-8">
        <Link href="/" className="flex items-center space-x-2">
          <Image src={nullifyLogo} alt="Logo" className="h-9 w-full" />
        </Link>
        {sessionStore.currentInstallation && (
          <>
            <DashboardTopNavigation
              links={[
                {
                  title: 'Overview',
                  href: '/dashboard/overview/',
                },
                {
                  title: 'Triage',
                  href: '/dashboard/triage/',
                },
                {
                  title: 'Chat',
                  href: '/dashboard/chat/',
                },
                {
                  title: 'Explore',
                  href: '/dashboard/explore/',
                },
                {
                  title: 'Configure',
                  href: '/dashboard/configure/',
                },
              ]}
            />
            <div className="flex flex-1 flex-grow items-center justify-end space-x-4">
              <InstallationSwitcher
                className="text-black"
                currentInstallation={sessionStore.currentInstallation}
                setCurrentInstallation={sessionStore.setCurrentInstallation}
                installationsData={sessionStore.installationsData}
              />
              <Link href="/logout">Logout</Link>
            </div>
          </>
        )}
      </div>
    </header>
  );
}
